import { McMultiChoiceFieldset } from '../mc-multi-choice-fieldset';

/**
 * @element `mc-radio-group`
 * @extends McMultiChoiceFieldset
 *
 * @event change - Fired when any radio in the group is checked.
 *
 * @slot - One or more `<mc-radio>` elements to display in the group.
 * @slot `legend` - The legend HTML to use for the mc-radio-group.
 */
export class McRadioGroup extends McMultiChoiceFieldset {}

declare global {
  interface HTMLElementTagNameMap {
    'mc-radio-group': McRadioGroup;
  }
}

customElements.get('mc-radio-group') || customElements.define('mc-radio-group', McRadioGroup);
