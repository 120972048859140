import { PropertyValues } from 'lit';
import { property, query } from 'lit/decorators.js';

// utils
import { DisabledState } from '@maersk-global/mds-components-utils';

// types
import { Orientation } from '@maersk-global/mds-shared-types';
import { IMcListItem, AriaRoles, McListItemType, JustifyItems, McListValueType, Target } from '../mc-list-item/types';
import { McTextAndIcon } from '../mc-text-and-icon';
import { McButton } from '../mc-button';

// mds-components used with mc-list-item
import '../mc-button';
import '../mc-checkbox';

export class McListItemBase extends DisabledState(McTextAndIcon) implements IMcListItem {
  @query('mc-button')
  protected mcButton?: McButton;

  @property({ type: String })
  public type: McListItemType = 'button';

  @property({ type: Boolean, reflect: true })
  public selected = false;

  @property({ type: Boolean, reflect: true })
  public focused = false;

  @property({ type: String })
  public justifyitems?: JustifyItems;

  @property({ type: Boolean })
  public hiddencheckmark = false;

  @property({ type: String })
  public href = '';

  @property({ type: String })
  public target?: Target;

  @property({ type: String })
  public ariarole: AriaRoles = 'listitem';

  @property({ type: String, attribute: true, reflect: true })
  public orientation: Orientation = 'vertical';

  @property({ type: Number })
  public tabindex?: number;

  @property({ type: Boolean, reflect: true })
  public visible?: boolean = true;

  @property({ type: String, reflect: true })
  public value: McListValueType = undefined;

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('icon') && this.icon && this.mcButton) {
      this.mcButton.cssclass = this.hasLeadingIcon() ? 'icon-leading' : undefined;
    }

    if (changedProperties.has('trailingicon') && this.trailingicon && this.mcButton) {
      this.mcButton.cssclass = this.hasTrailingIcon() ? 'icon-trailing' : undefined;
    }
  }

  protected getJustifyItems(): JustifyItems {
    return this.justifyitems ? this.justifyitems : this.trailingicon ? 'space-between' : 'left';
  }

  public focus(): void {
    this.mcButton?.focus();
  }

  public blur(): void {
    this.mcButton?.blur();
  }
}
