// lit-elements
import { CSSResultArray } from 'lit';
// styles
import { styles } from './styles/index.styles';
// types & base class
import { IMcSelect, IMcSelectOptionSelectedDetail } from './types';
import { IMcSelectOption, McSelectBase, ListTypes } from '../base-class/mc-select-base';

/**
 * @element `mc-select`
 *
 * @event {InputEvent} input - Fired when the option is selected.
 * @event {CustomEvent<IMcSelectOptionSelectedDetail>} optionselected - Fired when the option is selected, includes detail option item object.
 *
 * @slot - The default slot where the options and dividers go
 * @slot `label` - The label HTML to use for the mc-input.
 * @slot `hint` - The hint HTML to use for the mc-input.
 * @slot `errormessage` - The errormessage HTML to use for the mc-input.
 *
 * @csspart `label-container` - for changing visuals of label container
 * @csspart `label` - for changing visuals of label
 * @csspart `selected-option` - for changing visuals of selected option field
 */

export class McSelect extends McSelectBase implements IMcSelect {
  protected listType: ListTypes = 'single';

  protected labelSelector = '.labels';

  /* styles */
  static get styles(): CSSResultArray {
    return [super.styles, styles];
  }

  constructor() {
    super();
    this.readonly = true;
    this.trailingicon = 'chevron-down';
    this.clearbutton = true;
    this.keepclearbuttonvisible = true;
  }

  /* render */

  /* event handlers */
  protected async setOptionSelected(el: IMcSelectOption): Promise<void> {
    this.selectedOption = el;
    if (this.selectedOption) {
      this.value = this.selectedOption.value === 'null' ? '' : (this.selectedOption.value as string);
      this.selectedOptionLabel = this.getDisplayLabel();
      this.dispatchEvent(new InputEvent('input'));
      this.dispatchEvent(
        new CustomEvent<IMcSelectOptionSelectedDetail>('optionselected', {
          detail: { value: this.value, label: this.selectedOption.innerText },
          composed: true,
          bubbles: true,
        })
      );
      if (this.mcPopover && this.mcPopover.hide) {
        this.mcPopover.hide();
      }
      await this.updateComplete;
      this.inputElement?.focus();
    }
  }

  /* utils */
  protected getOptions(): void {
    if (this.slotElements) {
      this.options = Array.from(this.slotElements).filter((el): boolean => {
        return el.nodeName.toLowerCase() === 'mc-option';
      });
    }
  }

  protected getDisplayLabel(): string | Node | undefined {
    if (this.selectedOption) {
      const children = this.selectedOption.childNodes;
      let labelSlotText: IMcSelectOption | Node | null = null;
      if (children.length > 0) {
        labelSlotText = this.selectedOption.cloneNode(true);
        (labelSlotText as IMcSelectOption).fit = this.fit;
      }
      return this.selectedOption.label || !labelSlotText ? this.selectedOption.label : labelSlotText;
    }

    return '';
  }

  protected getSelectedOption(): void {
    if (this.options && this.options.length > 0) {
      const stringifiedValue = JSON.stringify(this.value);
      this.selectedOption = this.options?.find((el) => stringifiedValue === JSON.stringify(el.value));
      if (this.selectedOption) {
        this.selectedOption.selected = true;
      }
    }
  }

  protected getFocusedOption(): IMcSelectOption | null | undefined {
    if (this.selectedOption) {
      if (this.selectedOption.value === 'null') {
        return this.options ? this.options[0] : null;
      }
      return this.selectedOption;
    }
    return this.options ? this.options[0] : null;
  }

  protected handleValueLabelSlotChange(): void {
    if (this.selectedOption) {
      this.value = this.selectedOption.value;
      this.selectedOptionLabel = this.getDisplayLabel();
    } else {
      this.value = '';
    }
  }
}
customElements.get('mc-select') || customElements.define('mc-select', McSelect);
