import { CSSResultArray, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import { styles } from './styles/index.styles';

// components
import '../mc-button-group-item';

// types
import { McList } from '../mc-list';
import { ListTypes } from '../mc-list/types';
import { McListItem } from '../mc-list-item';
import { IMcButtonGroup } from './types';

/**
 * @element` mc-button-group`
 * @summary Combines individual buttons that have a logical relationship into a cohesive group.
 * @since 2.27.0
 *
 * @event {CustomEvent<IMcListChangeDetail>} listchange - Emitted when a button group item is interacted.
 * @event {CustomEvent<IMcListItemsLoadedDetail>} listitemsloaded - Emitted when the button group items are loaded.
 * @event {CustomEvent<IMcListFocusChangeDetail>} focuschange - Emitted when a button group item receives focus.
 *
 * @slot - The default slot where the button group items go.
 *
 * @csspart `list` - for changing visuals of the inner list container.
 */
export class McButtonGroup extends McList implements IMcButtonGroup {
  @property({ type: String, reflect: true })
  public selectiontype: ListTypes = 'none';

  static get styles(): CSSResultArray {
    return [super.styles, styles];
  }

  public constructor() {
    super();
    this.width = 'auto';
    this.disablecircularkeynav = true;
    this.keynavigationtype = 'tab';
    this.disablehandlemousemove = true;
    this.noborder = true;
    this.scrollToSelectedValue = true;
    this.orientation = 'horizontal';
  }

  protected firstUpdated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties);
    this.adjustSeparatorsOnSelectedItems();
  }

  protected willUpdate(changedProperties: PropertyValues): void {
    super.willUpdate(changedProperties);

    if (changedProperties.has('selectiontype')) {
      this.type = this.selectiontype;
    }
  }

  protected handleListChange(item: McListItem | null | undefined): void {
    super.handleListChange(item);

    if (item) {
      this.adjustSeparatorsOnSelectedItems(item);
    }
  }

  private adjustSeparatorsOnSelectedItems(currentItem?: McListItem): void {
    if (currentItem) {
      this.adjustSeparatorsByItem(currentItem);
      return;
    }

    this.allItems?.forEach((item) => {
      if (item.selected) {
        this.adjustSeparatorsByItem(item);
      }
    });
  }

  private adjustSeparatorsByItem(currentItem: McListItem): void {
    if (this.allItems) {
      const afterSelectedClassName = 'after-selected';
      const itemIndex = this.allItems.indexOf(currentItem);

      if (this.type === 'single') {
        const currentAfterSelectedItem = this.allItems.find((item) => item.classList.contains(afterSelectedClassName));

        if (currentAfterSelectedItem) {
          currentAfterSelectedItem.classList.remove(afterSelectedClassName);
        }
      }

      if (itemIndex != null && itemIndex < this.allItems.length - 1) {
        if (currentItem.selected) {
          this.allItems[itemIndex + 1].classList.add(afterSelectedClassName);
        } else {
          this.allItems[itemIndex + 1].classList.remove(afterSelectedClassName);
        }
      }
    }
  }
}
customElements.get('mc-button-group') || customElements.define('mc-button-group', McButtonGroup);
