// lit-elements
import { CSSResultArray, html, PropertyValues, TemplateResult } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property, state } from 'lit/decorators.js';
// material
import { DialogBase } from './material/mwc-dialog/mwc-dialog-base.js';
import { strings } from './material/dialog/constants.js';
// styles
import { styles } from './styles/index.styles';
// types
import { Dimension, IMcModal, Fit, Padding } from './types';

// mds-components used with mc-modal
import '../mc-button';

/**
 * @element `mc-modal`
 *
 * @event {CustomEvent<McModalAction>} closed -  The closed event gets dispatched once the modal is closed.
 * @event {CustomEvent<McModalAction>} closing - The closing event gets dispatched once the modal is closing.
 * @event {CustomEvent} opened -  The opened event gets dispatched once the modal is opened.
 * @event {CustomEvent} opening - The opening event gets dispatched once the modal is opening.
 * @event {MouseEvent} click - The click event gets dispatched when clicked on the primaryAction/secondaryAction button within a modal.
 *
 * @slot `default` - The body HTML to use for the modal.
 * @slot `primaryAction` - The primaryAction HTML to use for the modal.
 * Could be used for i.e. custom confirm/cancel buttons.
 * @slot `secondaryAction` - The secondaryAction HTML to use for the modal.
 * Could be used for i.e. custom confirm/cancel buttons.
 */

export class McModal extends DialogBase implements IMcModal {
  /**
   * Used in material/mwc-dialog/mwc-dialog-base.js to toggle the footer if empty.
   */
  @state()
  private hiddenfooter = false;

  /**
   * Used in material/mwc-dialog/mwc-dialog-base.js to toggle the heading if empty.
   */
  @state()
  private hiddenheading = false;

  @property({ type: Boolean })
  public backdropcloseactiondisabled = false;

  @property({ type: String })
  public dimension: Dimension = 'medium';

  @property({ type: Boolean })
  public entercloseactiondisabled = false;

  @property({ type: Boolean })
  public escapecloseactiondisabled = false;

  @property({ type: String, reflect: true })
  public fit: Fit = 'medium';

  @property({ type: String })
  public height?: string;

  @property({ type: Boolean })
  public hiddenclose = false;

  @property({ type: String })
  public padding?: Padding = 'default';

  @property({ type: String })
  public width?: string;

  @property({ type: Number })
  public zindex = 999;

  static get styles(): CSSResultArray {
    return styles;
  }

  /* render methods */
  protected render(): TemplateResult {
    const classes = {
      [`${this.fit}`]: true,
      [`dimension--${this.dimension}`]: true,
      [`hiddenclose`]: this.hiddenclose,
      'padding-none': this.padding === 'none',
    };
    return html`<div class="mc-modal ${classMap(classes)}" data-cy="modal-wrapper">${super.render()}</div>`;
  }

  /* lifecycle methods */

  public willUpdate(changedProperties: PropertyValues): void {
    super.willUpdate(changedProperties);

    if (changedProperties.has('backdropcloseactiondisabled')) {
      this.setBackdropCloseAction();
    }

    if (changedProperties.has('escapecloseactiondisabled')) {
      this.setEscapeCloseAction();
    }

    if (changedProperties.has('entercloseactiondisabled')) {
      this.setEnterCloseAction();
    }
  }

  /* utils */
  private setBackdropCloseAction(): void {
    if (this.backdropcloseactiondisabled) {
      this.scrimClickAction = '';
    } else {
      this.scrimClickAction = strings.CANCEL_ACTION;
    }
  }

  private setEscapeCloseAction(): void {
    if (this.escapecloseactiondisabled) {
      this.escapeKeyAction = '';
    } else {
      this.escapeKeyAction = strings.CANCEL_ACTION;
    }
  }

  private setEnterCloseAction(): void {
    if (this.entercloseactiondisabled) {
      this.enterKeyAction = '';
    } else {
      this.enterKeyAction = strings.CLOSE_ACTION;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mc-modal': McModal;
  }
}

customElements.get('mc-modal') || customElements.define('mc-modal', McModal);
