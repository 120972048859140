import { CellCustomizationPayload, Fit, LabelPosition, Position, Variant } from '@maersk-global/mds-shared-types';
import { IMcCalendar } from '../mc-calendar/types';
import { IMcInput } from '../mc-input/types';

export const INPUT_DATE_DEFAULTS = {
  calendarPosition: 'bottom-left' as Position,
  fit: 'medium' as Fit,
  format: 'YYYY-MM-DD',
  labelPosition: 'top' as LabelPosition,
  locale: new Intl.NumberFormat().resolvedOptions().locale || 'en-GB',
  nextLabel: 'Next month',
  previouslabel: 'Previous month',
  startOfWeek: 1,
  variant: 'default' as Variant,
  yearCap: 20,
  width: 'auto',
};

export interface IMcInputDate extends Omit<IMcCalendar, 'value' | 'min' | 'max' | 'focus'>, IMcInput {
  /** Start of the week (0 = Sunday, 1 = Monday, ...)
   *  @default 1
   */
  startofweek?: number;
  /** Date format to be used to populate input field. Check The day.js website for available formatting options
   * @default YYYY-MM-DD
   */
  format?: string;
  /**
   * A set of dates to customize.
   */
  customize?: CellCustomizationPayload[];
  /**
   * Position on the calendar.
   * @default 'bottom-left'
   */
  calendarposition?: Position;
  /**
   * How many years backwards and forwards from the current one to render in month-year picker.
   */
  yearcap?: number;
  /**
   * Whether to open the calendar on render.
   * @default false
   */
  open?: boolean;
}
